import { getProductUpdateInfoNew } from 'apis/pms';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { ProductUpdateInfoNewType } from 'types/pms';
import getProductDisplayInfo from 'utils/get-product-display-info';

const useProductDisplayInfo = ({
  productId,
  lang,
}: {
  productId?: number;
  lang: string;
}) => {
  const {
    productCategoryCascaderOptions,
    colorSelectOptions,
    rankSelectOptions,
    stampSelectOptions,
    materialCascaderOptionsMap,
    hardwareSelectOptions,
    accessorySelectOptions,
  } = useAppSelector(selectGlobalInfo);

  const [productInfo, setProductInfo] = useState<ProductUpdateInfoNewType>();

  useEffect(() => {
    if (!productId) return;
    getProductUpdateInfoNew(productId).then((res) => {
      setProductInfo(res);
    });
  }, [productId]);

  return useMemo(() => {
    if (!productInfo) return {};
    const {
      name,
      color,
      rank,
      rankDescription,
      i18nList,
      material,
      hardware,
      stamp,
      accessory,
      productCategoryId,
    } = productInfo;

    return getProductDisplayInfo({
      lang,
      productInfo: {
        name,
        color,
        rank: rank || '',
        rankDescription,
        i18nList: i18nList || [],
        material,
        hardware,
        stamp: stamp || '',
        accessory,
        productCategoryId,
      },
      options: {
        productCategoryCascaderOptions,
        materialCascaderOptionsMap,
        colorSelectOptions,
        rankSelectOptions,
        stampSelectOptions,
        hardwareSelectOptions,
        accessorySelectOptions,
      },
    });
  }, [
    productInfo,
    lang,
    productCategoryCascaderOptions,
    materialCascaderOptionsMap,
    colorSelectOptions,
    rankSelectOptions,
    stampSelectOptions,
    hardwareSelectOptions,
    accessorySelectOptions,
  ]);
};

export default useProductDisplayInfo;
