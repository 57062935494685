import { OmsRecycleOrderDetail } from 'types/oms';
import { SelectOption } from 'types/base';
import { MallCity } from 'types/home';
import { getCityListByCountryCode } from 'apis/home';
import { SysUser } from 'types/sys';
import { DempyouType, DempyouPrintDto } from 'pages/prints/dempyou';
import dayjs from 'dayjs';
import i18n from 'i18n';
import LOCALS from 'commons/locals';
import { LANGUAGE_MAP, SHOP_MAP } from 'commons/options';

export interface ProductType {
  productName: string;
  productPrice: string | number;
  guestRemarks: string;
  type: number;
  code: string;
  productSn: string;
  brandName: string;
  productId: number;
  submissionId?: number;
}

export type PrintParamType = Pick<
  DempyouPrintDto,
  | 'code'
  | 'date'
  | 'payType'
  | 'staff'
  | 'userInfo'
  | 'memberId'
  | 'submissionId'
  | 'storeId'
> &
  Partial<Pick<DempyouPrintDto, 'dempyouType' | 'totalPrice'>> & {
    type: number;
  };

const getDempyouLang = (storeId?: number) => {
  if (!storeId) return i18n.language;

  if ([SHOP_MAP.GINZA].includes(storeId)) {
    return LANGUAGE_MAP.JA;
  }

  if ([SHOP_MAP.HONGKONG, SHOP_MAP.SINGAPORE].includes(storeId)) {
    return LANGUAGE_MAP.EN;
  }

  return i18n.language;
};

const getDempyouProduct = (
  data: OmsRecycleOrderDetail,
  { name }: { name?: string }
): ProductType | undefined => {
  if (data) {
    const { omsRecycleOrderProduct, omsRecycleOrderItem, omsRecycleOrder } =
      data;
    const { productSn, brandName } = omsRecycleOrderProduct || {};
    const {
      finalSalePrice = '',
      finalRecyclePrice = '',
      guestRemarks = '',
      productId,
    } = omsRecycleOrderItem || {};
    const { type, code, submissionId } = omsRecycleOrder || {};
    return {
      productName: name || '',
      productPrice: finalSalePrice || finalRecyclePrice || '',
      productSn: productSn || '',
      brandName: brandName || '',
      guestRemarks,
      type: type!,
      code: code!,
      productId: productId || 0,
      submissionId,
    };
  }
  return;
};

const getDempyouOrderInfo = async (
  data: OmsRecycleOrderDetail,
  {
    staffSelectOptions,
    countryOptions,
    idCertificate,
  }: {
    staffSelectOptions: (SelectOption & Pick<SysUser, 'shop'>)[];
    countryOptions: (SelectOption & {
      nameI18n: {
        en: string;
        ja: string;
        zh_CN: string;
      };
    })[];
    idCertificate?: string;
  }
): Promise<PrintParamType | undefined> => {
  const lang = getDempyouLang(data.omsRecycleOrder?.storeId);

  let cityStr: MallCity | undefined = undefined;
  const { omsRecycleOrderLogistics, omsRecycleOrder } = data;
  const { code, settlementType, createBy, type, createTime, submissionId } =
    omsRecycleOrder || {};
  const { name, phone, postCode, country, city, detailAddress } =
    omsRecycleOrderLogistics || {};
  const cityData = await getCityListByCountryCode(country || '', lang);
  const countryOption = countryOptions.find((d) => d.value === country);
  const countryIsJP = countryOption?.value === 'JPN';
  const countryLabel = (() => {
    if (countryIsJP) return '';
    if (!countryOption?.nameI18n) return countryOption?.label;

    return (
      countryOption?.nameI18n[lang as keyof typeof countryOption.nameI18n] ||
      countryOption?.label
    );
  })();
  const nameStr = countryIsJP ? `${name || '-'}  様` : `${name || '-'}`;
  cityStr = cityData.data.cityList?.find((d) => d.code === city);

  const staff =
    staffSelectOptions.find((d) => d.value === createBy)?.label || '';
  const payType = [
    '',
    i18n.t(LOCALS.iJVtIJxKRd, { lng: lang }),
    i18n.t(LOCALS.XLtWNsvkPj, { lng: lang }),
  ][settlementType || 1];

  const userInfo = (() => {
    const arr = [
      nameStr,
      // 香港需要展示用户的身份证信息
      phone
        ? data.omsRecycleOrder?.storeId === SHOP_MAP.HONGKONG
          ? `+${phone}        ${idCertificate || ''}`
          : `+${phone}`
        : '',
      `${countryLabel} ${cityStr?.name || ''} ${detailAddress || ''} ${
        postCode || ''
      }`,
    ];

    return arr
      .map((i) => i?.trim())
      .filter(Boolean)
      .join('\n');
  })();

  return {
    userInfo,
    staff,
    code: code || '',
    payType,
    type: type!,
    date: dayjs(createTime).format('YYYY/MM/DD'),
    memberId: data.omsRecycleOrder?.memberId || 0,
    submissionId,
    storeId: data.omsRecycleOrder?.storeId,
  };
};

const getDempyouToPrint = ({
  productList,
  printParam,
  prints,
}: {
  productList: ProductType[];
  printParam: PrintParamType;
  prints?: boolean; // 是否弹窗打印
}) => {
  const payload = {
    productList,
    ...printParam,
    totalPrice: productList.reduce((total, product) => {
      const price = Number(product.productPrice) || 0;
      return total + price;
    }, 0),
    prints,
    memberId: printParam.memberId,
  };
  const [t1, t2] =
    printParam.type === 1
      ? [DempyouType.sell_customer, DempyouType.sell_store]
      : [DempyouType.recycle_customer, DempyouType.recycle_store];

  // 回收类型 = 2 张
  const printList = [
    { ...payload, dempyouType: t1 },
    { ...payload, dempyouType: t2 },
  ];
  const encode = encodeURIComponent(JSON.stringify(printList));

  window.open(`/prints/dempyou?body=${encode}`);
};

export {
  getDempyouProduct,
  getDempyouOrderInfo,
  getDempyouToPrint,
  getDempyouLang,
};
