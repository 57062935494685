// 消费税税率 10%
export const CONSUMPTION_TAX_RATE = 0.1;

export const OMS_ORDER_TAG_OPTION_LIST = [
  {
    value: 'B2B',
    label: 'B2B',
    color: 'red',
  },
];
