import { CascaderOption } from 'types/base';
import findCascaderOptionById from './findCascaderOptionById';
import { SelectOptionLabelList } from 'store/slices/globalInfoSlice';

const getProductDisplayInfo = ({
  productInfo,
  lang,
  options: {
    productCategoryCascaderOptions,
    materialCascaderOptionsMap,
    colorSelectOptions,
    rankSelectOptions,
    stampSelectOptions,
    hardwareSelectOptions,
    accessorySelectOptions,
  },
}: {
  lang: string;
  productInfo: {
    name: string;
    rankDescription: string;
    i18nList: {
      lang?: string;
      name?: string;
      attrRankDesc?: string;
    }[];
    productCategoryId: number;
    stamp: string;
    rank: string;
    color: string;
    material: string;
    hardware: string;
    accessory: string;
  };
  options: {
    productCategoryCascaderOptions: CascaderOption[];
    materialCascaderOptionsMap: Record<string, CascaderOption[]>;
    colorSelectOptions: SelectOptionLabelList;
    rankSelectOptions: SelectOptionLabelList;
    stampSelectOptions: SelectOptionLabelList;
    hardwareSelectOptions: SelectOptionLabelList;
    accessorySelectOptions: SelectOptionLabelList;
  };
}) => {
  if (!productInfo) return {};
  const {
    name,
    color,
    rank,
    rankDescription,
    i18nList,
    material,
    hardware,
    stamp,
    accessory,
  } = productInfo;
  const labelKey =
    lang === 'en'
      ? 'labelEn'
      : lang === 'ja'
      ? 'labelJa'
      : lang === 'zh_TW'
      ? 'labelTw'
      : lang === 'zh_CN'
      ? 'labelCn'
      : 'label';

  // 获取商品分类
  const productCategory = findCascaderOptionById(
    productInfo.productCategoryId,
    productCategoryCascaderOptions
  );
  const category = (() => {
    if (!productCategory) return '';

    const { nameEn, nameJa, nameTw, nameZh } = productCategory;
    if (lang === 'en') {
      return nameEn || '';
    } else if (lang === 'ja') {
      return nameJa || '';
    } else if (lang === 'zh_TW') {
      return nameTw || '';
    } else if (lang === 'zh_CN') {
      return nameZh || '';
    }

    return '';
  })();

  // 获取商品材质
  const materialArr: string[] = [];
  const productCategoryIds =
    productCategory?.treeIds?.split(',')?.map(Number) || [];
  const mateList = materialCascaderOptionsMap[productCategoryIds[0]] || [];
  material &&
    material
      .split(',')
      .filter(Boolean)
      .forEach((d: string) => {
        const t = findCascaderOptionById(d, mateList);
        if (t) {
          materialArr.push(t[labelKey] || '');
        }
      });

  // 获取商品等级
  const rankStr = rankSelectOptions.find((d) => d.value === rank)?.[labelKey];

  // 获取商品颜色
  const colorArr: string[] = [];
  color &&
    color.split(',')?.forEach((d: string) => {
      const t = colorSelectOptions.find((dd) => dd.value === d);
      if (t) {
        colorArr.push(t[labelKey] || '');
      }
    });

  // 获取商品硬件
  const hardwareArr: string[] = [];
  hardware &&
    hardware.split(',').forEach((value) => {
      const t = hardwareSelectOptions.find((dd) => dd.value === value);
      if (t && t.value !== 'none') hardwareArr.push(t[labelKey] || '');
    });

  // 获取商品印章
  const stampStr =
    stampSelectOptions.find((d) => d.value === stamp && d.value !== 'none')?.[
      labelKey
    ] || '';

  // 获取商品配件
  const accessoryArr: string[] = [];
  accessory &&
    accessory.split(',').forEach((value) => {
      const t = accessorySelectOptions.find((dd) => dd.value === value);
      if (t) accessoryArr.push(t[labelKey] || '');
    });

  return {
    name: lang === 'en' ? name : i18nList?.find((i) => i.lang === lang)?.name,
    category,
    material: materialArr.filter(Boolean).join(' '),
    color: colorArr.filter(Boolean).join(' '),
    rankDesc:
      lang === 'en'
        ? rankDescription
        : i18nList?.find((i) => i.lang === lang)?.attrRankDesc,
    rank: rankStr,
    hardware: hardwareArr.filter(Boolean).join(' '),
    stamp: stampStr,
    accessory: accessoryArr.filter(Boolean).join(' '),
  };
};

export default getProductDisplayInfo;
